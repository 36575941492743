<template>

<div class="login">

	<div class="login-box">

		<app-icon icon="manager.logo" class="login-icon" />

		<app-logo class="login-logo" term="manager" />

		<app-input-text :noLine="true" :disabled="is.loggingin" :stacked="true" v-model="form.email" placeholder="Enter e-mail address" maxlength="128" />
		<app-input-text :noLine="true" :disabled="is.loggingin" :stacked="true" ref="password" :password="true" v-model="form.password" placeholder="Enter password" maxlength="128" />

		<app-button text="Login" class="login-button" v-on:click.native.stop="onLoginClick" :disabled="!isLoginValid" :loading="is.loggingin" />

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loggingin: false
			},
			reason: '',
			form: {
				email: '',
				password: ''
			}
		}

	},

	computed: {

		isLoginValid: function() {

			return this.form.email && this.form.password
		
		}

	},

	methods: {

		onLoginClick: function() {

			this.is.loggingin = true

			this.$store.dispatch('session/login', {
				form: this.form
			}).then(function() {

				if (this.$route.query.redirect) {

					this.$router.push({
						path: this.$route.query.redirect
					})

				} else {

					this.$router.push({
						name: 'Dashboard'
					})

				}

			}.bind(this), function(json) {
				
				this.is.loggingin = false
				this.reason = json.reason

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.login-icon {
	font-size: 96px;
	color: #fff;
	text-align: center;
}

.login-icon >>> i {
	--fa-primary-color: #ffffff;
	--fa-secondary-color: #ffbe7a;
	--fa-secondary-opacity: 1;
} 

.login-logo {
	margin: 4px 0px;
}

.login {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #1e3054;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-logo {
	margin-bottom: 10px;
}

.login-button {
	width: 100%;
}

</style>
